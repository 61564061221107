@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

/*********************** Tag selectors ************************/
/* . - class # - id div */
html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}


/*********************** Header ************************/

.header-main * {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: black;
}

.header-main li,
.header-main a,
.header-main button {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #edf0f1;
  text-decoration: none;
}

.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 2%;
  background-color: black;
}


/* Placeholder class for logo */
/

.nav-links {
  width: auto;
  list-style: none;
  text-align: center;
  vertical-align: middle;
}

.nav-links li {
  display: inline-block;
  
  margin-left: 20px;
  padding: 0px 20px;
}

.nav-links li a {
  transition: all 0.3s ease 0s;
}

.nav-links li a:hover {
  color: orange;
}

.header-main button {
  padding: 9px 25px;
  /* background-color: rgba(0, 136, 169, 1); */
  background-color: orange;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.header-main button:hover {
  background-color: #ff5722;
}

.right-content-logged-in h4 {
  color: #edf0f1;
}

.right-content-logged-in * {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
}



/*********************** NewEvents ************************/

.NewEvents {
  
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.NewEventsTitle {
  margin-top: 30%;
  font-size: 80px;
  font-family: "Inter", sans-serif;
  color: #5ad67d;
  
  animation: NewEventsTitle 5s 1;
}
@keyframes NewEventsTitle {
  0% {
    opacity: 0;
  }
  /* 20% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  60%{
    opacity: 1;
  } */
  80%{
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

.animation-area2 {
  text-align: center;
  z-index: -1;
  height: 100vmax;
  width: 100%;
  position: absolute;
  background: black;
}
.box-area2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 92%;
    height: 96%;
    overflow: hidden;
    /* margin-bottom:-160px; */
  
}
/*********************** Login ************************/

.login * {
  font-family: "Inter", sans-serif;
  /* font-size: 14px; */
  /* text-align: center; */
}

.login .form-box {
  border-radius: 10px;
  width: 380px;
  height: 480px;
  position: relative;
  margin: 6% auto;
  background: #fff;
  padding: 5px;
  overflow: hidden;
}

.login .form-box.long {
  border-radius: 10px;
  width: 380px;
  height: 550px;
  position: relative;
  margin: 6% auto;
  background: #fff;
  padding: 5px;
  overflow: hidden;
}

.button-box {
  width: 220px;
  margin: 35px auto;
  position: relative;
  box-shadow: 0 0 20px 9px #ff61241f;
  border-radius: 30px;
}
.toggle-btn {
  padding: 10px 30px;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  position: relative;
}

#btn {
  top: 0;
  left: 0;
  position: absolute;
  width: 110px;
  height: 100%;
  background: linear-gradient(to right, #fa7d09, #ffb570);
  border-radius: 30px;
  transition: 0.5s;
}

.input-group {
  top: 100px;
  position: absolute;
  width: 280px;
  transition: 0.5s;
}

.input-field {
  width: 100%;
  padding: 10px 0;
  margin: 5px 5px 15px 5px;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #999;
  outline: none;
  background: transparent;
}

.submit-btn {
  width: 85%;
  padding: 10px 30px;
  cursor: pointer;
  display: block;
  margin: 20px;
  background: linear-gradient(to right, #fa7d09, #ffb570);
  border: 0;
  outline: none;
  border-radius: 30px;
  font-size: 15px;
}

#login {
  left: 50px;
}
#register {
  left: 450px;
}

.animation-area {
  text-align: center;
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
  background: black;
}

.box-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 92%;
  height: 96%;
  overflow: hidden;
  /* margin-bottom:-160px; */
}
.box-area li {
  position: absolute;
  display: block;
  list-style: none;
  width: 25px;
  height: 25px;
  animation: animated 20s linear infinite;
  bottom: -20%;
}

.box-area li:nth-child(1) {
  background: orange;
  left: 14%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.box-area li:nth-child(2) {
  background: orange;
  left: 12%;
  width: 30px;
  height: 30px;
  animation-delay: 1.5s;
  animation-duration: 10s;
}

.box-area li:nth-child(3) {
  background: orange;
  left: 70%;
  width: 100px;
  height: 100px;
  animation-delay: 5.5s;
}

.box-area li:nth-child(4) {
  background: orange;
  left: 86%;
  width: 150px;
  height: 150px;
  animation-delay: 1s;
  animation-duration: 13s;
}

.box-area li:nth-child(5) {
  background: orange;
  left: 26%;
  width: 40px;
  height: 40px;
  animation-delay: 4.5s;
  animation-duration: 10s;
}

.box-area li:nth-child(6) {
  background: orange;
  left: 15%;
  width: 110px;
  height: 110px;
  animation-delay: 3.5s;
}

.box-area li:nth-child(7) {
  background: orange;
  left: 30%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.box-area li:nth-child(8) {
  background: orange;
  left: 17%;
  width: 80px;
  height: 80px;
  animation-delay: 5s;
}

.box-area li:nth-child(9) {
  background: orange;
  left: 70%;
  width: 50px;
  height: 50px;
  animation-delay: 0s;
  animation-duration: 8s;
}

.box-area li:nth-child(10) {
  background: orange;
  left: 76%;
  width: 80px;
  height: 80px;
  animation-delay: 3s;
  animation-duration: 8s;
}

.box-area li:nth-child(11) {
  background: orange;
  left: 22%;
  width: 80px;
  height: 80px;
  animation-delay: 1.5s;
  animation-duration: 6s;
}
@keyframes animated {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(-1200px) rotate(360deg);
    opacity: 0;
  }
}

@media screen and (max-width: 376px) {
  .login .form-box {
    width: 90%;
  }

  .input-group {
    width: 255px;
  }
}

/*********************** Store ************************/

.store-img {
  height: 150px;
  width: 150px;
}

/*********************** About ************************/
.wave {
  
}

section.wave{

}
.about-main {
  font-family: "Inter", sans-serif;
  /* background: #020230; */
  display: flex;
  overflow: hidden;
  align-items: center;
}

.about-main .container {
  margin: auto;
  /* padding-top: 50px; */
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  z-index: 1;
  /* flex-wrap: wrap; */
  /* padding: 10%; */
}

#about-main-sec {
  width: 100%;
  height: 90vh;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  /* background: linear-gradient(to right,#020230,rgb(255, 137, 28)); */
  background: linear-gradient(to left, #8942a8, #ba382f);
}
/*#020224*/
section .wave {
  position: absolute;
  width: 100%;
  height: 143px;
  bottom: 0;
  left: 0;
  background: url(images/wave.png);
  animation: animate 20s linear infinite;
}

section .wave:before {
  content: "";
  width: 100%;
  height: 143px;
  background: url(images/wave.png);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  animation: animate-reverse 20s linear infinite;
}

section .wave:after {
  content: "";
  width: 100%;
  height: 143px;
  background: url(images/wave.png);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  animation-delay: -5s;
  animation: animate 30s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 1360px;
  }
}

@keyframes animate-reverse {
  0% {
    background-position: 1360px;
  }
  100% {
    background-position: 0;
  }
}

.about-main button {
  padding: 9px 25px;
  /* background-color: rgba(0, 136, 169, 1); */
  background-color: orange;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  font-size: 18px;
}

.about-main button:hover {
  background-color: #ff5722;
}

.about-main .about-info {
  /* width: 1200px; */
  /* max-width: 500px; */
  color: #fff;
  /* padding-right: 5%; */
  padding-left: 10%;
}

.about-info h2 {
  font-size: 90px;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 10%;
}

.about-info p {
  font-weight: 300;
  line-height: 1.9;
}


.about-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(-30deg) translateX(-500px);
}

/* .about-main .shadow {
  position: absolute;
  top: 560px;
  left: 800px;
  height: 5px;
  width: 800px;
  background: #000;
  border-radius: 50%;
  filter: blur(5px);
  transform: skewY(5deg);
} */

.about-main .glass {
  position: absolute;
  top: 50%;
  left: 50%;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .about-main #main-img{
    border: 2px solid #fff;
    overflow: hidden;
    width: 45%;
    height: 45%;
    transform: translateX(-15%) skewY(5deg);
    
  }
  }

@media screen and (max-width: 1024px) {
  .about-main #main-img {
    margin: auto;
    width: 350px;
    margin-top: 5%;
    transform: skewY(5deg);
  }
  .about-main .container {
    margin: auto;
    text-align: center;
    padding: 0;
    flex-wrap: wrap;
  }

  .about-main .about-info {
    margin: auto;
    padding-left: 0;
    text-align: center;
  }
  .about-main h2 {
    text-align: center;
    padding-right: 0;
    font-size: 65px;
  }
}

.about-description {
  font-family: "Inter", sans-serif;
  background: white;
}

.about-description-row {
  display: flex;
  padding: 5% 0 5% 0;
}

.about-description-col {
  flex: 50%;
  align-items: center;
  text-align: center;
}

.about-description-col#first {
  margin-left: 20%;
}

.about-description-col#second {
  margin-right: 20%;
}

.about-description p {
  padding: 0 8% 0 8%;
}

.about-description h2 {
  color: #1b1c25;
  font-weight: 700;
  font-size: 35px;
  margin: 0;
}

.about-description p {
  font-weight: 300;
}

.about-reasons {
  text-align: center;
  font-family: "Inter", sans-serif;
  background-color: #f1f1f1dc;
  padding: 0.5%;
}

.about-reasons h1 {
  color: #1b1c25;
}

.about-reasons-row {
  display: flex;
}

.about-reasons-row .column {
  flex: 33%;
  /* padding: 10px; */
  /* height: 300px; */
}

.about-reasons-row .column.c1 {
  margin-left: 20%;
}

.about-reasons-row .column.c3 {
  margin-right: 20%;
}

.column p {
  /* width: 10%; */
  padding: 0 17% 0 17%;
  font-size: 16px;
  font-weight: 300;
}

.about-reasons-row h2 {
  color: orange;
  margin-top: 20px;
  margin-bottom: 0;
  color: #1b1c25;
  font-size: 21px;
}

.fins-row {
  display: flex;
  font-family: "Inter", sans-serif;
  max-width: 1300px;
  margin: auto;
  /* padding: 0 50px;
   */
  padding: 50px 0 50px 0;
  justify-content: space-between;
  align-items: center;
}

.fins-col {
  flex: 50%;
}

.fins-col.first {
  margin-left: 10%;
}

.fins-col.second {
  text-align: center;
  margin-right: 10%;
}

@media screen and (max-width: 960px) {
  .fins-row {
    flex-wrap: wrap-reverse;
    max-width: 600px;
  }
  .fins-col.first {
    width: 100%;
    margin-bottom: 60px;
  }

  .fins-col.second {
    width: 100%;
    text-align: center;
  }
}

/* @media screen and (max-width:700px){
  .btn{
    margin: 0;
    width: 100%;
    margin-top: 20px;
  }
} */

/*********************** Blog ************************/

.blog-main {
  font-family: "Montserrat", sans-serif;
}

/*********************** The Team ************************/
@keyframes meet-team {
  from {
    background-color: lightblue;
  }
  to {
    background-color: sienna;
  }
}

/* @keyframes team-members {
    from {background-color: linear-gradient(lightblue 20%, white 80%);}
    to {background-color: linear-gradient(sienna 20%, white 80%);
    }
  } */
.team-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-button button {
  padding: 18px 40px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: white;
  background: orange;
  border: none;
  font-weight: 700;
  outline: none;
  border-radius: 30px;
  margin-left: 20px;
  transition: opacity 0.3s linear;
  cursor: pointer;
}

.team-button button:hover {
  background-color: #ff5722;
}

#team-section {
  font-family: "Montserrat", sans-serif;
  margin: 0px;
  /* padding-left: 50px;
  padding-right: 50px;
  padding-top: 0px;
  padding-bottom: 0px; */
  display: flex;
  flex-direction: column;
  /* animation: meet-team 3s infinite;
  animation-direction: alternate;
  width: 100%; */
  background-image: url("./images/team-background.png");
}

#team-members {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  /* background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(241,241,241,1) 5%); */
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  margin-top: 110px;
  animation: team-members 3s infinite;
  animation-direction: alternate;
  /* padding-left: 150px;
    padding-right: 150px;
    padding-top: 0px;
    padding-bottom: 0px;  */
  justify-content: center;
  align-items: center;
  text-align: center;
}

#team-position {
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  font-weight: 1000;
  text-shadow: 2px 2px 1px grey;
}

.team-title {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 3rem;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 1000;
  text-shadow: 2px 2px 1px grey;
}

.team-main {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.team-pres {
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  background: rgb(241, 241, 241);
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: flex-start;
}

/* .pres-title{

  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
} */

.pres-bg {
  background: rgb(241, 241, 241);
  width: 100%;
}

.team-fin {
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  background: white;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: flex-start;
}

.fin-bg {
  background: white;
  width: 100%;
}

.team-software {
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  background: rgb(241, 241, 241);
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: flex-start;
}

.sft-bg {
  background: rgb(241, 241, 241);
  width: 100%;
}
.team-graphic {
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  background: white;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: flex-start;
}

.gra-bg {
  background: white;
  width: 100%;
}

.team-social {
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  background: rgb(241, 241, 241);
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: flex-start;
}

.soc-bg {
  background: rgb(241, 241, 241);
  width: 100%;
}

.team-external {
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  background: white;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: flex-start;
}

.ext-bg {
  background: white;
  width: 100%;
}

.team-planning {
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  background: rgb(241, 241, 241);
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: flex-start;
}

.pla-bg {
  background: rgb(241, 241, 241);
  width: 100%;
}

.team-com {
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  background: white;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: flex-start;
}

.com-bg {
  background: white;
  width: 100%;
}

.member {
  font-family: "Montserrat", sans-serif;
  /* flex: 1; */
  text-align: center;
  padding: 30px;
  justify-content: center;
  align-items: center;
  max-width: 400px;
}

.member img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 5px solid black;
}

.member-title {
  display: flex;
  flex-direction: column;
}

/*******************Team-Modal****************/

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  border: 2px solid black;
  border-radius: 5px;
  z-index: 10;
  background-color: rgb(241, 241, 241);
  width: 400px;
  max-width: 80%;
  transition: 300ms ease-in-out;
}

#Nassim.modal.active.Nassim {
  transform: translate(-50%, -50%) scale(1);
}
#Rylan.modal.active.Rylan {
  transform: translate(-50%, -50%) scale(1);
}
#Declan.modal.active.Declan {
  transform: translate(-50%, -50%) scale(1);
}
#Anant.modal.active.Anant {
  transform: translate(-50%, -50%) scale(1);
}
#Muaz.modal.active.Muaz {
  transform: translate(-50%, -50%) scale(1);
}
#Sartaj.modal.active.Sartaj {
  transform: translate(-50%, -50%) scale(1);
}
#Ben.modal.active.Ben {
  transform: translate(-50%, -50%) scale(1);
}
#Nathan.modal.active.Nathan {
  transform: translate(-50%, -50%) scale(1);
}
#Colin.modal.active.Colin {
  transform: translate(-50%, -50%) scale(1);
}
#Hridhoy.modal.active.Hridhoy {
  transform: translate(-50%, -50%) scale(1);
}
#Ali.modal.active.Ali {
  transform: translate(-50%, -50%) scale(1);
}





.modal img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 2px solid black;
  justify-content: center;
  text-align: center;
}

.member button {

  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  border-radius: 50%;
  
}

.modal-title {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid black;
}

.modal-title .title {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-weight: 1000;
  text-shadow: 1px 1px 1px grey;
}

.modal-pic {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 15px;
  border-bottom: 2px solid black;
}

.modal-description {
  padding: 15px;
}

.modal-title .close-button {
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  font-size: 1.75rem;
  font-weight: 1000;
}

#overlay {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 5;
  pointer-events: none;
  transition: 300ms ease-in-out;
}

#overlay.active.Nassim {
  opacity: 1;
  pointer-events: all;
}
#overlay.active.Rylan {
  opacity: 1;
  pointer-events: all;
}
#overlay.active.Declan {
  opacity: 1;
  pointer-events: all;
}
#overlay.active.Sartaj {
  opacity: 1;
  pointer-events: all;
}
#overlay.active.Rasik {
  opacity: 1;
  pointer-events: all;
}
#overlay.active.Muaz {
  opacity: 1;
  pointer-events: all;
}
#overlay.active.Anant {
  opacity: 1;
  pointer-events: all;
}
#overlay.active.Hridhoy {
  opacity: 1;
  pointer-events: all;
}
#overlay.active.Colin {
  opacity: 1;
  pointer-events: all;
}
#overlay.active.Ben {
  opacity: 1;
  pointer-events: all;
}
#overlay.active.Ali {
  opacity: 1;
  pointer-events: all;
}
#overlay.active.Nathan {
  opacity: 1;
  pointer-events: all;
}
/*********************** Footer ************************/
.footer-container {
  font-family: "Inter", sans-serif;
  max-width: 1300px;
  margin: auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
}

.logo {
  width: 180px;
}

.social-media {
  margin: 20px 0;
}

.social-media a {
  color: #001a21;
  margin-right: 22px;
  text-decoration: none;
  transition: 0.3s linear;
}

.social-media a:hover {
  color: orange;
}

.right-col h1 {
  font-size: 26px;
}

.border {
  width: 100px;
  height: 4px;
  background: orange;
}

.newsletter-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/* .txtb {
  flex: 1;
  padding: 18px 40px;
  font-size: 16px;
  color: #293043;
  background: #ddd;
  border: none;
  font-weight: 700;
  outline: none;
  border-radius: 30px;
  min-width: 260px;
} */

.right-col input {
  flex: 1;
  padding: 18px 40px;
  font-size: 16px;
  color: #293043;
  background: #ddd;
  border: none;
  font-weight: 700;
  outline: none;
  border-radius: 30px;
  min-width: 260px;
  margin-top: 5px;
}

/* .btn {
  padding: 18px 40px;
  font-size: 16px;
  color: #293043;
  background: orange;
  border: none;
  font-weight: 700;
  outline: none;
  border-radius: 30px;
  margin-left: 20px;
  transition: opacity .3s linear;
} */

.right-col button {
  padding: 18px 40px;
  font-size: 16px;
  color: #293043;
  background: orange;
  border: none;
  font-weight: 700;
  outline: none;
  border-radius: 30px;
  margin-left: 20px;
  transition: opacity 0.3s linear;
}

/* .btn:hover {
  opacity: .7;
  cursor: pointer;
} */

.right-col button:hover {
  opacity: 0.7;
  cursor: pointer;
}

footer {
  background: #f1f1f1;
  padding: 90px 0;
}

@media screen and (max-width: 960px) {
  .footer-container {
    max-width: 600px;
  }
  .right-col {
    width: 100%;
    margin-bottom: 60px;
  }

  .left-col {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 700px) {
  .right-col button {
    margin: 0;
    width: 100%;
    margin-top: 20px;
  }
}

/*********************** Exec App ************************/

.inputBox .exec-select {
  border: 1px solid orange;
}

.inputBox .exec-select select {
  width: 100%;
  border: 0;
  background: transparent;
  font-size: 16px;
  font-family: "Inter", sans-serif;
}

section.exec {
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(
      30deg,
      white 12%,
      transparent 12.5%,
      transparent 87%,
      white 87.5%,
      white
    ),
    linear-gradient(
      150deg,
      white 12%,
      transparent 12.5%,
      transparent 87%,
      white 87.5%,
      white
    ),
    linear-gradient(
      30deg,
      white 12%,
      transparent 12.5%,
      transparent 87%,
      white 87.5%,
      white
    ),
    linear-gradient(
      150deg,
      white 12%,
      transparent 12.5%,
      transparent 87%,
      white 87.5%,
      white
    ),
    linear-gradient(
      60deg,
      rgb(245, 245, 245) 25%,
      transparent 25.5%,
      transparent 75%,
      rgb(245, 245, 245) 75%,
      rgb(245, 245, 245)
    ),
    linear-gradient(
      60deg,
      rgb(245, 245, 245) 25%,
      transparent 25.5%,
      transparent 75%,
      rgb(245, 245, 245) 75%,
      rgb(245, 245, 245)
    );

  /* background-color:rgb(255, 185, 32);
  background-image: linear-gradient(30deg, black 12%, transparent 12.5%, transparent 87%, black 87.5%, black),
  linear-gradient(150deg, black 12%, transparent 12.5%, transparent 87%, black 87.5%, black),
  linear-gradient(30deg, black 12%, transparent 12.5%, transparent 87%, black 87.5%, black),
  linear-gradient(150deg, black 12%, transparent 12.5%, transparent 87%, black 87.5%, black),
  linear-gradient(60deg, rgb(255, 193, 111) 25%, transparent 25.5%, transparent 75%, rgb(255, 193, 111) 75%, rgb(255, 193, 111)),
  linear-gradient(60deg, rgb(255, 193, 111) 25%, transparent 25.5%, transparent 75%, rgb(255, 193, 111) 75%, rgb(255, 193, 111)); */

  background-size: 80px 140px;
  background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
  height: 1600px;
}

.contact-main {
  /* top: -25%; */
  margin-top: 5%;
}

/*********************** Contact ************************/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

section {
  position: relative;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  min-height: 75vh;
  /* background: orange;
  font-family: "Montserrat", sans-serif; */
  font-family: "Inter", sans-serif;
}
section:not(.exec) {
  /* min-height: 100vh; */
  margin-bottom: 50px;
}

/* section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  background-color: #eeecda;
  background: url("./images/about-img-3.png") no-repeat center/cover;
} */

/* THERE ARE A WHOLE BUNCH OF GOOD COMBOS TO BE MADE USING THE OPACITY AND BLUR ~ PICK ONE ~ Nassim */
section.contact::before {
  content: "";
  position: absolute;
  display: flex;
  top: -5px;
  left: 0;
  width: 100%;
  height: 130%;
  background: url("./images/about-img-3.png") center/cover;
  opacity: 0.8;
  filter: blur(4px);
  /* background-color: black; */
}

section .contact-main {
  position: relative;
  min-width: 1100px;
  min-height: 550px;
  display: flex;
  z-index: 1000;
}

section .contact-main .contact-info {
  position: absolute;
  top: 40px;
  width: 320px;
  /* height: calc(100%-80px); */
  background: orange;
  border-style: solid;
  border: black;
  z-index: 1;
  padding: 40px 0 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
  /* font-size: 24px; */
  border-radius: 15px;
}

section .contact-main .contact-info h2 {
  color: #323000;
  font-size: 24px;
  font-weight: 700;
}

section .contact-main .contact-info .info,
section .contact-main .contact-info .info.exec {
  position: relative;
  margin: 0 0;
  padding: 0px 0px;
}

section .contact-main .contact-info .info li {
  position: relative;
  list-style: none;
  display: flex;
  margin: 20px 0;
  /* cursor: pointer; */
  align-items: flex-start;
}
section .contact-main .contact-info .info li span:nth-child(1) {
  width: 30px;
  min-width: 30px;
}

section .contact-main .contact-info .info li span:nth-child(1) img {
  max-width: 100%;
  filter: invert(1);
  opacity: 0.5;
}

section .contact-main .contact-info .info li span:nth-child(2) {
  color: black;
  margin-left: 10px;
  font-weight: 300;
  opacity: 0.5;
}

section .contact-main .contact-info .info li:hover span:nth-child(1) img,
section .contact-main .contact-info .info li:hover span:nth-child(2) {
  opacity: 1;
}

section .contact-main .contact-info .socIcons {
  position: relative;
  display: flex;
  padding: 0px 0px;
}

section .contact-main .contact-info .socIcons li {
  list-style: none;
  margin-right: 15px;
}

section .contact-main .contact-info .socIcons li a {
  text-decoration: none;
}
/* section .contact-main .contact-info .socIcons li a i .fa-facebook {
  font-size: 16px;
  color: blue;
} */

section .contact-main .contact-info .socIcons li a i {
  filter: invert(1);
  opacity: 0.6;
  font-size: 1.5rem;
}

section .contact-main .contact-info .socIcons li:hover a i {
  opacity: 1;
}

section .contact-main .inputs {
  position: absolute;
  padding: 70px 50px;
  background: #f3f9fb;
  border-style: solid;
  border: orange;
  margin-left: 150px;
  padding-left: 250px;
  /* width: calc(100%-150px); */
  box-shadow: 0 50px 50px rgba(0, 0, 0, 0.5);
  /* border-radius: 20px; */
}
section .contact-main .inputs:not(.exec) {
  height: fit-content;
}

section .contact-main .inputs h2 {
  color: orange;
  font-size: 26px;
  font-weight: 700;
}

section .contact-main .inputs .formBox {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
}
section .contact-main .inputs .formBox .inputBox {
  position: relative;
  margin: 0 0 35px 0;
}
section .contact-main .inputs .formBox .inputBox.w50 {
  width: 47%;
}
section .contact-main .inputs .inputBox.w100 {
  width: 100%;
}

section .contact-main .inputs .inputBox.w100.long {
  width: 100%;
  transform: translate(0px, 20px);
}

section .contact-main .inputs .formBox .inputBox input,
section .contact-main .inputs .formBox .inputBox textarea {
  font-family: "Inter", sans-serif;
  width: 100%;
  padding: 5px 0;
  resize: none;
  font-size: 18px;
  font-weight: 300;
  color: black;
  background-color: #f3f9fb;
  border: none;
  border-bottom: 1px solid orange;
  outline: none;
}
section .contact-main .inputs .formBox .inputBox textarea {
  min-height: 120px;
}
section .contact-main .inputs .formBox .inputBox span {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: black;
  transition: 0.5s;
  pointer-events: none;
}

section .contact-main .inputs .formBox .inputBox input:focus ~ span,
section .contact-main .inputs .formBox .inputBox textarea:focus ~ span,
section .contact-main .inputs .formBox .inputBox input:valid ~ span,
section .contact-main .inputs .formBox .inputBox textarea:valid ~ span {
  transform: translateY(-20px);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: orange;
}

section .contact-main .inputs .formBox .inputBox.long textarea:focus ~ span,
section .contact-main .inputs .formBox .inputBox.long textarea:valid ~ span {
  transform: translateY(-50px);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: orange;
}

.contact-main .submit-btn {
  /*font-family: "Montserrat", sans-serif;*/
  position: relative;
  left: 25%;
  top: -20px;
  background: orange;
  color: black;
  border: orange;
  font-size: 20px;
  text-align: center;
  letter-spacing: 1px;
  height: 50px;
  margin-top: 20px;
  border-radius: 0;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  width: 300px;
  padding: 12px;
}

.contact-submit-disabled:hover {
  cursor: not-allowed;
  opacity: 0.5;
}

.contact-main .submit-btn:hover:not(.disabled) {
  background-color: black;
  color: orange;
}

#message-confirm {
  font-family: "Inter", sans-serif;
}

#error-text {
  color: red;
}

/*********************** Sponsors ************************/

.sponsors-main {
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

/*********************** Store ************************/

.store-main {
  font-family: "Montserrat", sans-serif;
}

/*********************** Events ************************/

.MainContent {
  background-color: black;
  background-image: radial-gradient(
      coral,
      rgba(126, 126, 126, 0.2) 2px,
      transparent 40px
    ),
    radial-gradient(cyan, rgba(255, 255, 255, 0.15) 1px, transparent 30px),
    radial-gradient(cyan, rgba(255, 255, 255, 0.1) 2px, transparent 40px),
    radial-gradient(red, rgba(255, 255, 255, 0.1) 2px, transparent 30px);
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
}

.OuterTitleDiv {
  position: relative;
  top: 18px;
  display: block;
  margin: 0;
  font-family: sans-serif;
  /* background: black; */
}

.OuterTitleDiv .TitleDiv {
  text-align: center;
  position: relative;
  /* padding-top:20px; */
  margin-left: -1px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  width: 100%;
}

.TitleDiv span {
  text-transform: uppercase;
  display: block;
}

.text1 {
  color: orange;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 8px;
  margin-bottom: 20px;
  /* background: black; */
  position: relative;
  animation: text 5s 1;
  margin-bottom: 10px;
}

.text2 {
  font-size: 30px;
  color: #5ac7d6;
  animation: text2anime 5s 1;
}
/* .text2::after{
  position: absolute;
  top:-2px;
  right: -2px;
  left: -2px;
  bottom: -2px;
  background: red;
  z-index: -2;
  filter: blur(20px);
} */
@keyframes text2anime {
  0% {
    opacity: 0;
  }
  88% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes text {
  0% {
    color: fuchsia;
    letter-spacing: 45px;
    margin-bottom: -40px;
  }
  50% {
    margin-top: auto;
    /* color: red; */
    letter-spacing: 45px;
    margin-bottom: -40px;
  }
  85% {
    margin-top: auto;
    letter-spacing: 8px;
    margin-bottom: -40px;
  }
}

.EventsIntro {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  /* background: url("../../images/about-img-2.png") */
  /* z-index: -1; */
}

.EventsIntro img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
  filter: blur(2px);
}

/* .EventsIntro h1{
  position: relative;
  text-align: center;
  font-size: 70px;
  font-weight: 700;
  color: orange;
 

} */

.events-box-area {
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  width: 97%;
  height: 105%;
  overflow: hidden;
  /* margin-bottom:-160px; */
}
.events-box-area li {
  position: absolute;
  display: block;
  list-style: none;
  width: 25px;
  height: 25px;
  left: -5%;
  animation: eventsanimated 10s linear infinite;
}

.events-box-area li:nth-child(1) {
  background: orange;
  top: 50%;
  width: 40px;
  height: 40px;
  animation-delay: 0s;
}
.events-box-area li:nth-child(2) {
  background: orange;
  top: 60%;
  width: 80px;
  height: 80px;
  animation-delay: 4s;
}
.events-box-area li:nth-child(3) {
  background: orange;
  top: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 3s;
}
.events-box-area li:nth-child(4) {
  background: orange;
  top: 30%;
  width: 50px;
  height: 50px;
  animation-delay: 2s;
}
.events-box-area li:nth-child(5) {
  background: orange;
  top: 70%;
  width: 30px;
  height: 30px;
  animation-delay: 1s;
}

@keyframes eventsanimated {
  0% {
    opacity: 1;
    background: fuchsia;
  }
  25% {
    transform: translateX(400px) translateY(200px) rotate(240deg);
    opacity: 0.25;
  }
  50% {
    transform: translateX(800px) translateY(0) rotate(480deg);
    opacity: 1;
  }
  75% {
    transform: translateX(1200px) translateY(-200px) rotate(720deg);
    opacity: 0.25;
  }
  100% {
    transform: translateX(1600px) translateY(0) rotate(960deg);
    opacity: 0;
  }
}

.Info-session {
  display: flex;
  justify-content: center;
  /* background: linear-gradient(180deg, black ,#060c21, cyan); */
  /* background:black; */
  z-index: 1;
}

.Info2 {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 5% 5% 5%;
}

.InfoBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: blue; */
  width: 600px;
  height: 800px;
  /* margin-right: 10%; */
  /* background: linear-gradient(235deg, #fa7d09 ,#060c21, #00bcd4) */
  background: black;
  z-index: -1000;
}

.InfoBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 600px;
  height: auto;
  z-index: 2;
  /* margin-right: 10%; */
  background: linear-gradient(235deg, cyan, #060c21, red);
  /* background: red; */
}

.InfoBox:before {
  content: "";
  position: absolute;

  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  z-index: 1;
  background: black;
  /* border-radius: 5px; */
}

.InfoBox:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: 0;
  background: linear-gradient(235deg, cyan, #060c21, red);
  filter: blur(40px);
  animation: backanime1 5s linear infinite;
}

@keyframes backanime1 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.55;
  }
  100% {
    opacity: 1;
  }
}

/* .InfoBox::before{
  content: '';
  position: absolute;
  top:-2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background: black;
  border-radius: 5px;

}

.InfoBox::after{
  content: '';
  position: absolute;
  top:-10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: -2;
  background: #ffffff;
  filter: blur(40px);

}


.InfoBox::before, .InfoBox::after {
  background: linear-gradient(235deg, cyan ,#060c21, black);
} */

.Infolist {
  padding: 20px;
  border-style: border-box;

  z-index: 2;
  font-family: "Inter", sans-serif;
}

.InfoBox h1 {
  position: relative;
  color: orange;
  font-size: 70px;
}

/* .InfoBox h3 {
  color: white;
  font-weight: 700;
  font-size: 30px;
} */

/* .InfoBox p {
  font-size: 25px;
  font-weight: 700;
  margin:auto;
  padding-top:40px;
  line-height: 2.0;
  color: white;
} */

.infoimg {
  position: relative;
  width: 464px;
  height: 786px;
  /* background: rebeccapurple; */
}
.infoimg .screen {
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}

.infoimg:hover .screen.Screen1 {
  opacity: 1;
}
.infoimg:hover .screen.Screen2 {
  transform: translateX(-45px) translateY(-20px);
  opacity: 0.25;
}
.infoimg:hover .screen.Screen3 {
  transform: translateX(-90px) translateY(-40px);
  opacity: 0.5;
}
.infoimg:hover .screen.Screen4 {
  transform: translateX(-135px) translateY(-60px);
  opacity: 0.75;
}

.infoimg:hover .screen.Screen5 {
  transform: translateX(-180px) translateY(-80px);
  opacity: 1;
}
/* This is the Compiler  */

.Compiler {
  position: relative;
  display: flex;
  justify-content: center;
  /* background: linear-gradient(180deg, cyan,black,black,black,black,black,black,black, black, black ,black, black); */
  z-index: 1;
}

.Comp2 {
  display: flex;
  margin: 5% 5% 5%;
  justify-content: space-evenly;
  width: 100%;
}

.CompilerContents {
  position: relative;
  list-style: none;
  font-family: "Inter", sans-serif;
  /* background: darkblue; */
  /* padding: 20px 20px 20px 20px; */
  color: orange;
  height: 800px;
  width: 600px;
  justify-content: flex-start;
  margin-right: 50px;
}

.CompetitionBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 600px;
  height: 800px;
  z-index: 2;
  /* margin-right: 10%; */
  background: linear-gradient(235deg, #fa7d09, #060c21, #00bcd4);
  /* background: red; */
}

.CompetitionBox:before {
  content: "";
  position: absolute;

  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  z-index: 1;
  background: black;
  /* border-radius: 5px; */
}

.CompetitionBox:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: 0;
  background: linear-gradient(235deg, red, #060c21, lime);
  filter: blur(40px);
  animation: backanime2 5s linear infinite;
}

@keyframes backanime2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.55;
  }
  100% {
    opacity: 1;
  }
}

/* .CompetitionBox {
  margin-left:50px;
  position: relative;
  
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 600px;
  height: 800px;
  
  background: linear-gradient(235deg, black ,black, coral);
  z-index: 0;
}



.CompetitionBox::before{
  content: '';
  position: absolute;
  top:-2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background:linear-gradient(235deg, black ,black, coral);
  border-radius: 5px;

}

.CompetitionBox::after{
  content: '';
  position: absolute;
  top:-10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: -2;
  border-radius: 10px;
  background: linear-gradient(235deg, black ,black, coral);
  filter: blur(40px);
}

.Competition::before, .Competition::after {
  background: linear-gradient(235deg, black ,black,#fa7d09);
} */

.Complist {
  padding: 20px;
  border-style: border-box;

  color: #7d8e60;
  font-family: "Inter", sans-serif;
  z-index: 2;
}

.CompetitionBox h1 {
  position: relative;
  color: orange;
  font-size: 70px;
}

/* .CompetitionBox h3 {
  color:white;
  font-weight: 700;
  font-size:30px;
}
.CompetitionBox p {
  font-size: 24px;
  font-weight: 700;
  margin:auto;
  padding-top:40px;
  line-height: 2.0;
  color: white;
} */

/* .InfoBox h1 {
  position: relative;
  color: orange;
  font-size: 70px;

}

.InfoBox h3 {
  color: white;
  font-weight: 700;
  font-size: 30px;
}

.InfoBox p {
  font-size: 25px;
  font-weight: 700;
  margin:auto;
  padding-top:40px;
  line-height: 2.0;
  color: white;
} */

.competitions-title {
  color: orange;
}
.competitions-text {
  color: orange;
  width: 100%;
  padding: 0;
  height: 100%;
  background-color: black;
  text-align: left;
  font-size: 24px;
  font-weight: 1000;
  font: "Inter", sans-serif;
  /* line-height: 1.9; */
}

.Competitions {
  position: relative;
}

.theeditor {
  border-style: solid;
  border-color: orange;
  border-width: 1px;
  padding-left: 225px;
}
.compiler-functions {
  display: flex;
  list-style: none;
  position: relative;
  margin-left: 0;
  margin-right: 10%;
  justify-content: center;

  /* align-items: flex-start; */
}

.compiler-functions li {
  margin: 20px 20px 20px 20px;
}

.compiler-functions select {
  color: orange;
  background: black;
  border-radius: 5px;
  border-style: none;
  font-weight: 500;
  font-size: 18px;
}

.compiler-submit-btn {
  position: relative;
  /* margin-right: 5%; */
  border: 1px solid orange;
  background-color: black;
  padding: 20px 30px;
  font-size: 20px;
  font-weight: 1000;
  font-family: "montserrat";
  cursor: pointer;
  transition: 0.8s;
  color: orange;
  position: relative;
  overflow: hidden;
  margin-top: 25px;
  margin-right: 30px;
  margin-left: 10px;
}
.compiler-submit-btn:hover {
  color: black;
  background-color: orange;
}

.compiler-submit-btn::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  z-index: -1;
  background: orange;
  transition: 0.8s;
  bottom: 0;
  border-radius: 50% 50% 0 0;
}

.compiler-submit-btn:hover::before {
  height: 180%;
}

.compiler-output {
  list-style: none;
  margin-left: -40px;
  padding-right: 40px;

  /* padding-bottom: 20px; */
}
.output-title {
  color: orange;
  font-size: 19px;
  margin-bottom: 20px;
  /* padding-left:20px; */
}
.output-data {
  list-style: none;
  background: black;
  font-weight: 700;
  padding-left: 10px;

  margin: auto;

  /* font-family: "montserrat"; */
  font-size: 16px;
  border-radius: 10px;
  border-style: solid;
  border-color: orange;
  border-width: 1px;
  width: auto;
  height: auto;
}

.output {
  color: lime;
}
.memory {
  color: red;
}

.cpuTime {
  color: fuchsia;
}

.Presentations {
  position: relative;
  display: flex;

  /* background: linear-gradient(180deg, black, black, black,fuchsia, fuchsia); */
  /* background:black; */
  justify-content: center;
  z-index: 1;
}

.Presentations2 {
  position: relative;
  display: flex;
  justify-content: space-evenly;

  width: 100%;
  margin: 5% 5% 5%;
  padding-right: 70px;
}

.PresentationsBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 600px;
  height: 800px;
  z-index: 2;
  /* margin-right: 10%; */
  background: linear-gradient(235deg, coral, #060c21, #00bcd4);
  /* background: red; */
}

.PresentationsBox:before {
  content: "";
  position: absolute;

  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  z-index: 1;
  background: black;
  /* border-radius: 5px; */
}

.PresentationsBox:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: 0;
  background: linear-gradient(235deg, gold, #060c21, fuchsia);
  filter: blur(40px);
  animation: backanime3 5s linear infinite;
}

@keyframes backanime3 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.55;
  }
  100% {
    opacity: 1;
  }
}

.presentations-img {
  position: relative;
  width: 550px;

  /* background:red; */

  /* background: rebeccapurple; */
}

.presentations-img li {
  list-style: none;
  position: relative;
  height: 800px;
  width: 600px;
  /* background: linear-gradient(180deg, crimson,purple, blue); */
  margin-top: -16px;

  animation: presanime 30s linear infinite;
  border-radius: 15px;
}

@keyframes presanime {
  0% {
    opacity: 1;
    background: red;
  }
  25% {
    background: purple;
  }
  50% {
    background: blue;
  }
  75% {
    background: coral;
  }
  100% {
    background: red;
  }
}

/* .Presentations .PresentationsBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 600px;
  height: 800px;
  background: black;
  z-index: 0;

}



.PresentationsBox::before{
  content: '';
  position: absolute;
  top:-2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background: black;
  border-radius: 5px;

}

.PresentationsBox::after{
  content: '';
  position: absolute;
  top:-10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: -2;
  background: #ffffff;
  filter: blur(40px);

}


.PresentationsBox::before, .PresentationsBox::after {
  background: linear-gradient(235deg, fuchsia ,#060c21, black);
} */

.Presentationslist {
  padding: 20px;
  border-style: border-box;
  color: white;
  font-family: "Inter", sans-serif;
  z-index: 2;
}

.PresentationsBox h1 {
  position: relative;
  color: orange;
  font-size: 70px;
}

.WWWW-info {
  margin-left: 5%;
  color: white;
  /* background: orange; */
  font-weight: 500;
  font-size: 20px;
}

.list-WWWW-info {
  list-style: none;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  /* border-style: solid;
  border-width: 1px;
  border-color: none; */
  padding: 10px;
}

.What {
  color: coral;
  font-weight: 700;
  font-size: 30px;
}
.Where {
  color: coral;
  font-weight: 700;
  font-size: 30px;
}
.Why {
  color: coral;
  font-weight: 700;
  font-size: 30px;
}
.When {
  color: coral;
  font-weight: 700;
  font-size: 30px;
}

/* .PresentationsBox p {
  font-size: 25px;
  font-weight: 700;
  margin:auto;
  padding-top:40px;
  line-height: 2.0;
  color: white;
}  */

.Workshops {
  position: static;
  display: flex;
  /* background: linear-gradient(180deg, fuchsia,black,black ,black, coral); */
  /* background:black; */
  justify-content: center;
  z-index: 1;
}

.Work2 {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  /* background:coral; */
  margin: 15% 5% 15%;
}

.WorkBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 600px;
  height: 800px;
  z-index: 2;
  /* margin-right: 10%; */
  background: linear-gradient(235deg, #fa7d09, #060c21, #00bcd4);
  /* background: red; */
}

.WorkBox:before {
  content: "";
  position: absolute;

  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  z-index: 1;
  background: black;
  /* border-radius: 5px; */
}

.WorkBox:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: 0;
  background: linear-gradient(235deg, #fa7d09, #060c21, #00bcd4);
  filter: blur(40px);
  animation: backanime4 5s linear infinite;
}

@keyframes backanime4 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.55;
  }
  100% {
    opacity: 1;
  }
}

.Worklist {
  padding: 20px;
  z-index: 2;
  color: white;
  font-family: "Inter", sans-serif;
}

.WorkBox h1 {
  position: relative;
  color: orange;
  font-size: 70px;
  z-index: 2;
}

/* .Workshops .WorkBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 600px;
  height: 800px;
  
  z-index: 0;
  

}



.WorkBox::before{
  content: '';
  position: absolute;
  top:-2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background: black;
  border-radius: 5px;

}

.WorkBox::after{
  content: '';
  position: absolute;
  top:-10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: -2;
  background: #ffffff;
  filter: blur(40px);

}


.WorkBox::before, .WorkBox::after {
  background: linear-gradient(235deg, magenta ,#060c21, #00ffff);
}

.Worklist {
  padding: 20px;
  
  color: white;
  font-family: "Inter", sans-serif;
}

.WorkBox h1 {
  position: relative;
  color: orange;
  font-size: 70px;

} */

.WorkImg {
  position: relative;
  height: auto;
  opacity: 1;
  width: 600px;
  /* background-color:black; */
  background-image: radial-gradient(
      white,
      rgba(255, 255, 255, 0.2) 2px,
      transparent 40px
    ),
    radial-gradient(white, rgba(255, 255, 255, 0.15) 1px, transparent 30px),
    radial-gradient(white, rgba(255, 255, 255, 0.1) 2px, transparent 40px),
    radial-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1) 2px,
      transparent 30px
    );
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
  /* border-radius: 50px; */
  border-style: solid;
  /* z-index: 1; */
  list-style: none;
  /* overflow: hidden; */
  border-width: 1px;
  border-color: coral;
  /* animation: sickback linear infinite 10s; */
}
/*   
  .WorkImg::after{
    content:'';
    position: absolute;
    z-index: -1;
    background: red;
    top: -10px;
    right:-10px;
    left:-10px;
    bottom:-10px;
    filter: blur(40px)
  } */

.WorkImgList {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 50px;
  
  
}
.WorkImgList li {
  margin: auto 5% 5% auto;
  position: relative;
  color: white;
  border-style: solid;
  border-color: coral;
  border-radius: 10px;
  list-style: none;
  border-width: 2px;
  padding: 10px;
  background: black;
  /* animation: sickback linear infinite 10s; */
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 13px;
  width:100%;
}

@keyframes sickback {
  0% {
    background: blue;
    opacity: 1;
  }
  50% {
    background: blueviolet;
    opacity: 1;
  }
  100% {
    background: blue;
    opacity: 1;
  }
}
.WorkImgList .scheduleh1 {
  border-color: none;
  margin-top: 10px;
  background: none;
  color: orange;
  font-weight: 900;
  font-size: 24px;
  animation: none;
  /* padding-left: 7%; */
  text-align: center;
}
.WorkCircles {
  position: relative;
  top: -250px;
}

.WorkCircles li {
  position: relative;
  display: block;
  animation: workanime 5s linear infinite;
  /* animation: workanimehov 5s linear infinite; */
  opacity: 0.15;
  /* z-index: 1; */
}
.WorkCircles li:hover {
  opacity: 1;
}

.WorkCircles li:nth-child(1) {
  top: 25%;
  left: 25%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(2) {
  top: 55%;
  left: 75%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}
.WorkCircles li:nth-child(3) {
  top: 25%;
  left: 75%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(4) {
  top: 55%;
  left: 25%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(5) {
  top: 30%;
  left: 40%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(6) {
  top: 65%;
  left: 85%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}
.WorkCircles li:nth-child(7) {
  top: 15%;
  left: 55%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(8) {
  top: 65%;
  left: 45%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}
.WorkCircles li:nth-child(9) {
  top: 35%;
  left: 50%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(10) {
  top: 45%;
  left: 35%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}
.WorkCircles li:nth-child(11) {
  top: 65%;
  left: 35%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(12) {
  top: 15%;
  left: 15%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(13) {
  top: 5%;
  left: 5%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(14) {
  top: 35%;
  left: 5%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}
.WorkCircles li:nth-child(15) {
  top: -20%;
  left: 50%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}
.cancer {
  min-height: 100vh;
  background: white;
}

.WorkCircles li:nth-child(16) {
  top: -30%;
  left: 25%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(17) {
  top: 25%;
  left: 65%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(18) {
  top: 35%;
  left: 65%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}
.WorkCircles li:nth-child(19) {
  top: 40%;
  left: 10%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(20) {
  top: -5%;
  left: 25%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(21) {
  top: -25%;
  left: 75%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(22) {
  top: -25%;
  left: 5%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}
.WorkCircles li:nth-child(23) {
  top: -15%;
  left: 65%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

.WorkCircles li:nth-child(24) {
  top: 55%;
  left: 35%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation-delay: 0s;
}

@keyframes workanime {
  0% {
    background: fuchsia;
    transform: TranslateY(-100px);
  }
  25% {
    background: coral;
    transform: TranslateY(-200px);
  }
  50% {
    background: cyan;
    transform: TranslateY(-400px);
  }
  75% {
    background: red;
    transform: TranslateY(-600px);
  }
  100% {
    background: fuchsia;
    transform: TranslateY(300px);
  }
}

/* .events-box-area {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  overflow: hidden;

}
.events-box-area li {
  position: absolute;
  display: block;
  list-style: none;
  width: 25px;
  height: 25px;
  left: -5%;
  animation: eventsanimated 10s linear infinite;
}

.events-box-area li:nth-child(1){
  background:orange;
  top: 50%;
  width: 40px;
  height: 40px;
  animation-delay: 0s;

} */

@media all and (max-width: 1600px) {
  .Info2 .InfoBox {
    margin-right: 20%;
  }
  .Comp2 .CompetitionBox {
    margin-left: 10%;
  }

  .Work2 .WorkImg {
    margin-right: 5%;
  }

  .Work2 .WorkBox {
  }
}

@media all and (max-width: 1400px) {
  /* inforesize */

  .InfoBox {
    height: 800px;
    width: 450px;
  }
  .Infolist {
    padding: 100px;
  }

  /* comp resize */

  .CompetitionBox {
    height: 800px;
    width: 450px;
  }
  .CompetitionBox h1 {
    font-size: 65px;
  }
  .Complist {
    padding: 100px;
  }
  .Comp2 {
    margin-right: 15%;
    width: 40%;
  }

  /* pres resize */
  .Presentations2 {
    width: 40%;
  }
  .PresentationsBox {
    height: 800px;
    width: 450px;
  }
  .PresentationsBox h1 {
    font-size: 62px;
  }
  .Presentationslist {
    padding: 100px;
  }

  /* work resize */
  .WorkBox {
    height: 800px;
    width: 450px;
  }
  .WorkBox h1 {
    font-size: 65px;
  }

  .WWWW-info {
    font-size: 16px;
  }

  .Why {
    font-size: 25px;
  }
  .Where {
    font-size: 25px;
  }
  .When {
    font-size: 25px;
  }
  .What {
    font-size: 25px;
  }

  /* ========================================================== */

  .events-box-area li {
    left: -15%;
  }

  .animation-area {
    /* width: 98%; */
  }
  .EventsIntro {
    background: black;
    z-index: 0;
  }
}

@media all and (max-width: 501px) {
  .OuterTitleDiv {
    padding-top: 5%;
  }
  .text1 {
    font-size: 15px;
    font-weight: 500;
  }
  .text2 {
    font-size: 15px;
  }

  .infoimg .Screen2,
  .infoimg .Screen3,
  .infoimg .Screen4,
  .infoimg .Screen5 {
    /* bottom: 20px; */
    display: none;
  }

  .infoimg375 {
    /* padding-top: 6000px; */
  }

  .Screen1 {
    /* padding-top: 6000px; */
  }

  .Info2 {
    /* background: red; */
    margin-bottom: 50px;
    padding-bottom: 50px;
  }

  .InfoBox {
    height: 440px;
    width: 300px;
  }

  .Infolist {
    padding: 0px;
    /* position: absolute; */
    /* height: 500px; */
    /* background: red; */
  }

  .InfoBox h1 {
    font-size: 30px;
    padding-left: 65px;
    padding-bottom: 0px;
  }

  /* comp resize */

  .CompetitionBox {
    height: auto;
    width: 300px;
    left: 15px;
  }
  .CompetitionBox h1 {
    font-size: 30px;
    padding-left: 58px;
    padding-top: 20px;
    padding-bottom: 0px;
  }
  .Complist {
    padding: 0px;
  }
  .Comp2 {
    margin-right: 15%;
    width: 40%;
  }

  /* pres resize */
  .Presentations2 {
    width: 100%;
    margin: 0;
  }
  .PresentationsBox {
    height: auto;
    width: 300px;
  }
  .PresentationsBox h1 {
    font-size: 30px;
    padding-left: 60px;
    padding-bottom: 0px;
    padding-top: 20px;
  }
  .Presentationslist {
    padding: 0px;
  }

  /* work resize */
  .WorkBox {
    height: auto;
    width: 300px;
  }
  .WorkBox h1 {
    font-size: 30px;
    padding-left: 50px;
    padding-top: 10px;
  }

  .WWWW-info {
    font-size: 10px;
  }

  .Why {
    font-size: 16px;
  }
  .Where {
    font-size: 16px;
  }
  .When {
    font-size: 16px;
  }
  .What {
    font-size: 16px;
  }

  /* .MainContent{
    width:375px;
    
   
  } */

  .infoimg {
    width: 320px;
    height: auto;
    /* background:red; */
    /* left:15px; */
    /* top:10px; */
  }
  .infoimg375 {
    /* max-width: 100%; */
    width: 80%;
    height: auto;
  }
  .screen {
    /* max-width: 100%; */
    width: 80%;
    height: auto;
  }
  .infoimg:hover .screen.Screen1 {
    opacity: 1;
  }
  .infoimg:hover .screen.Screen2 {
    transform: translateX(0px) translateY(0px);
    opacity: 0.25;
  }
  .infoimg:hover .screen.Screen3 {
    transform: translateX(0px) translateY(0px);
    opacity: 0.5;
  }
  .infoimg:hover .screen.Screen4 {
    transform: translateX(0px) translateY(0px);
    opacity: 0.75;
  }

  .infoimg:hover .screen.Screen5 {
    transform: translateX(0px) translateY(0px);
    opacity: 1;
  }

  /* the editor */

  .Div2Title {
    font-size: 20px;
    padding-left: 200px;
  }

  .theeditor {
    border-style: solid;
    border-color: orange;
    border-width: 1px;
    padding: 0;
    left: 150px;
  }
  .compiler-functions {
    display: flex;
    list-style: none;
    position: relative;
    padding: 0;

    margin: 0;
    justify-content: center;

    /* align-items: flex-start; */
  }

  .compiler-functions li {
    margin: 5px;
  }
  .compiler-functions h4 {
    font-size: 14px;
  }

  .compiler-functions select {
    color: orange;
    background: black;
    border-radius: 5px;
    border-style: none;
    font-weight: 500;
    font-size: 14px;
    width: 20px;
  }

  .compiler-submit-btn {
    position: relative;
    /* margin-right: 5%; */
    border: 1px solid orange;
    background-color: black;
    /* padding: 20px 30px; */
    font-size: 14px;
    font-weight: 900;
    font-family: "montserrat";
    cursor: pointer;
    transition: 0.8s;
    color: orange;
    position: relative;
    overflow: hidden;
    /* margin-top: 25px;
  margin-right: 30px;
  margin-left: 10px; */
  }
  .compiler-submit-btn:hover {
    color: black;
    background-color: orange;
  }

  .compiler-submit-btn::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0%;
    z-index: -1;
    background: orange;
    transition: 0.8s;
    bottom: 0;
    border-radius: 50% 50% 0 0;
  }

  .compiler-submit-btn:hover::before {
    height: 180%;
  }

  .compiler-output {
    list-style: none;
    /* margin-left: -40px;
  padding-right:40px; */

    /* padding-bottom: 20px; */
  }
  .output-title {
    color: orange;
    font-size: 16px;
    margin-bottom: 20px;
    /* padding-left:20px; */
  }
  .output-data {
    list-style: none;
    background: black;
    font-weight: 700;
    padding-left: 10px;

    margin-right: 0;
    margin-left: 145px;

    /* font-family: "montserrat"; */
    font-size: 12px;
    border-radius: 10px;
    border-style: solid;
    border-color: orange;
    border-width: 1px;
    width: 280px;
    height: auto;

    padding-right: 10px;
  }

  .output {
    color: lime;
    font-size: 12px;
  }
  .memory {
    color: red;
    font-size: 12px;
  }

  .cpuTime {
    color: fuchsia;
    font-size: 12px;
  }

  /*  */

  .Presentations {
    /* overflow: hidden; */
  }

  .presentations-img {
    width: 175%;
    height: 380px;
    /* background: cyan; */
    overflow: hidden;
  }
  .presentations-img li {
    width: 260px;
    height: 380px;
    animation: none;
    background: none;
    padding: 0;
    margin-left: 270px;
    overflow: hidden;
  }
  .presentations-img375 {
    width: 260px;
    height: 380px;
    background: purple;
    top: 10px;

    padding: 0;
    margin: 0;
    overflow: hidden;
  }


  







  .WorkImg{
    
    width:300px;
    height:600px;
    overflow: hidden;
  }

  .WorkImgList li {
    width: 240px;
    font-size: 8px;
    right: 24px;
    overflow: hidden;
  }
  .WorkImgList {
    width: 280px;
    overflow: hidden;
  }

  /* .WorkImg  {
    position: relative;
    height:800px;
    opacity: 1;
    width: 600px;
   
    background-image:
    radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 40px),
    radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 30px),
    radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 40px),
    radial-gradient(rgba(255,255,255,.4), rgba(255,255,255,.1) 2px, transparent 30px);
    background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
    background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
    
    border-style: solid;
    
    list-style:none;
    
    border-width: 1px;
    border-color: coral;
    
  }
  
  
  
  
  
  .WorkImgList {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right:50px;
    margin:auto;
    
    
  }
  .WorkImgList li {
    
    margin:auto 5% 5% auto;
    position: relative;
    color: white;
    border-style: solid;
    border-color: coral;
    border-radius:10px;
    list-style: none;
    border-width: 2px;
    padding:10px;
    background: black;
    
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    width:100%; */
}

.WorkImgList .scheduleh1 {
  border-color: none;
  margin-top: 10px;
  background: none;
  color: orange;
  font-weight: 900;
  font-size: 14px;
  animation: none;
  /* padding-left: 7%; */
  text-align: center;
}

.Info-session {
  overflow: hidden;
  padding-top: 40px;
  height: auto;
}
.Info2 {
  /* height: auto; */
  /* height: 250vh; */
}
.infoimg375 {
  top: 50px;
  /* bottom: 10px; */
}
.Compiler {
  overflow: hidden;
  padding-bottom: 0;
}
.Presentations {
  overflow: hidden;
}
.Compiler {
  padding-top: 40px;
  padding-bottom: 0;
  margin-bottom: 0;
}
.Presentations2 {
  padding-top: 80px;
}
.PresentationsBox:after {
  top: -1px;
}
.Workshops {
  /* overflow: hidden; */
}
@media all and (max-width: 370px) {
  .presentations-img li {
    right: 50px;
  }
}

@media all and (max-width: 1155px) {
  .Info2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Info2 .InfoBox {
    margin-top: 10%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 10%;

    padding: 0;
  }

  .Info2 .infoimg {
    margin: 0 10% 0 10%;
    padding: 0;
  }

  .Comp2 {
    padding: 0;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .Comp2 .CompetitionBox {
    margin: 0;
    padding: 0;
    margin: 10% 0 10% 0;
  }
  .Comp2 .CompilerContents {
    margin: 10% 0 10% 0;
  }
  .Presentations2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Presentations2 .PresentationsBox {
    margin: 0% 0 10% 0;
    left: 40px;
    padding: 0;
  }

  .Presentations2 .presentations-img {
    margin: 10% 0 10% 0;
    padding: 0;
    right: 50px;
  }
  .Work2 {
    padding: 0;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .Work2 .WorkBox {
    padding: 0;
    margin: 0% 0 10% 0;
    left: 0;
  }
  .Work2 .WorkImg {
    margin: 10% 0 10% 0;
  }

  /* =========================================================== */

  .events-box-area li {
    left: -20%;
  }
  .animation-area {
    /* width: 96%; */
  }
  .EventsIntro {
    background: black;
    z-index: 0;
  }
  .Presentations2 .presentations-img {
    right: 70px;
  }
  .text1 {
    font-size: 40px;
  }
  .text2 {
    font-size: 20px;
  }
  .EventsIntro h1 {
    font-size: 50px;
  }
}

@media all and (max-width: 760px) {
  /* ================================================= */
  .events-box-area li {
    left: -20%;
  }
  .animation-area {
    /* width: 94%; */
  }
  .EventsIntro {
    background: black;
    z-index: 0;
  }
  .Presentations2 .presentations-img {
    right: 70px;
  }
  .text1 {
    font-size: 35px;
  }
  .text2 {
    font-size: 15px;
  }
  .EventsIntro h1 {
    font-size: 40px;
  }
}

@media all and (max-width: 540px) {
  .animation-area {
    /* width: 94%; */
  }
  .EventsIntro {
    background: black;
    z-index: 0;
  }
  .Presentations2 .presentations-img {
    right: 70px;
  }
  .text1 {
    font-size: 35px;
  }
  .text2 {
    font-size: 15px;
  }
  .EventsIntro h1 {
    font-size: 40px;
  }
}

.container-about {
  padding: 4%;
}

.blog-card {
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  margin: 0.5rem auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6%;
  background: #fff;
  line-height: 1.4;
  border-radius: 10px;
  overflow: hidden;
  z-index: 0;
}

.blog-card a {
  color: inherit;
}

.blog-card a:hover {
  color: #5ad67d;
}

/* .blog-card:hover .photo {
  transform: scale(1.3) rotate(3deg);
} */

.blog-card .image-wrapper {
  position: relative;
  z-index: 0;
  height: 200px;
}

.blog-card .image-wrapper .image::before {
  content: "Learn more!";
  height: 100%;
  width: 100%;
  opacity: 0;
  /* position: absolute; */
  top: 0;
  left: 0;
  background-color: rgba(181, 181, 181, 0.3);
  transition: all 0.7s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.blog-card .image-wrapper .image:hover::before {
  opacity: 1;
}

.blog-card .image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s;
}

.blog-card .details,
.blog-card .details ul {
  margin: auto;
  padding: 0;
  list-style: none;
}

.blog-card .details {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  margin: auto;
  transition: left 0.2s;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  width: 100%;
  font-size: 0.9rem;
}

.blog-card .details a {
  text-decoration: dotted underline;
}

.blog-card .details ul li {
  display: inline-block;
}

.blog-card .details .author:before {
  /* font-family: FontAwesome; */
  margin-right: 10px;
  content: "";
}

.blog-card .details .date:before {
  /* font-family: FontAwesome; */
  margin-right: 10px;
  content: "";
}

.blog-card .details .tags ul:before {
  /* font-family: FontAwesome; */
  content: "";
  margin-right: 10px;
}

.blog-card .details .tags li {
  margin-right: 2px;
}

.blog-card .details .tags li:first-child {
  margin-left: -4px;
}

.blog-card .description {
  padding: 1rem;
  background: #fff;
  position: relative;
  z-index: 1;
}

.blog-card .description:before {
  content: "";
  border-radius: 10px;
  background: #fff;
  width: 100%;
  position: absolute;
  top: -10px;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.blog-card .description h1 {
  line-height: 1;
  margin: 0;
  font-size: 1.7rem;
  padding: 8px 0;
}

.blog-card .description h2 {
  font-size: 1rem;
  font-weight: 300;
  /* text-transform: uppercase; */
  color: #a2a2a2;
  margin-top: 5px;
}

.blog-card .description .card-actions {
  text-align: center;
}

.blog-card .description .card-actions a {
  display: inline-block;
  position: relative;
}

.blog-card .description .card-actions a:hover {
  color: black !important;
}

.blog-card p {
  position: relative;
  margin: 1rem 0 0;
}

.blog-card p:first-of-type {
  margin-top: 1.25rem;
}

.blog-card p:first-of-type:before {
  content: "";
  position: absolute;
  height: 5px;
  background: #5ad67d;
  width: 35px;
  top: -0.75rem;
  border-radius: 3px;
  text-align: left;
  height: 3px;
  border-radius: 50px;
  background: orange;
  /* width: 60px; */
}

.blog-card:hover .details {
  left: 0%;
}

@media (min-width: 640px) {
  .blog-card {
    flex-direction: row;
    max-width: 1200px;
    min-height: 160px;
  }
  .blog-card .image-wrapper {
    flex-basis: 40%;
    /* height: auto; */
    height: 350px;
  }
  .blog-card .image-wrapper.abfins {
    height: 500px;
  }
  .blog-card .description {
    flex-basis: 60%;
  }
  .blog-card .description:before {
    /* transform: skewX(-3deg); */
    content: "";
    border-radius: 10px;
    background: #fff;
    width: 30px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  .blog-card.alt {
    flex-direction: row-reverse;
  }
  .blog-card.alt .description:before {
    left: inherit;
    right: -10px;
    /* transform: skew(3deg); */
  }
  .blog-card.alt .details {
    padding-left: 25px;
  }

  .blog-card p {
    font-size: 20px;
  }
}

.abreasons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  background-attachment: fixed;
  background-color: rgb(241, 241, 241);
}

.container-abreasons {
  position: relative;
  width: 1150px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.container-abreasons .card {
  position: relative;
  width: 300px;
  height: 460px;
  background: #fff;
  margin: 20px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.container-abreasons:hover .card {
  filter: blur(20px);
  transform: scale(0.9);
  opacity: 0.5;
}

.container-abreasons .card:hover {
  filter: blur(0px);
  transform: scale(1.1);
  opacity: 1;
}

.container-abreasons .card .circle {
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
  clip-path: circle(180px at center 0);
  text-align: center;
  bottom: 13%;
}

.container-abreasons .card .circle h2 {
  color: #fff;
  font-size: 4.5em;
  padding: 30px 0;
}

.container-abreasons .card .content {
  position: absolute;
  bottom: 10px;
  padding: 25px;
  text-align: center;
}

.container-abreasons .card .content p {
  color: #666;
}

.container-abreasons .card .content a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background: #000;
  color: #fff;
  border-radius: 40px;
  text-decoration: none;
  margin-top: 20px;
}

.container-abreasons .card:nth-child(1) .circle,
.container-abreasons .card:nth-child(1) .content a {
  /* background: #ffaf00; */
  background-image: url("https://s3-us-west-2.amazonaws.com/medici-prod/root/Medici/public/uploads/custom2_year-in-review-what-happened-fintech-payments-2019.jpg?1585926305");
  background-repeat: no-repeat;
  background-size: 350px 190px;
}

.container-abreasons .card:nth-child(2) .circle,
.container-abreasons .card:nth-child(2) .content a {
  /* background: #da2268; */
  background-image: url("https://chiefmarketer.com/wp-content/uploads/2021/04/b2b1-scaled.jpg");
  background-repeat: no-repeat;
  /* background-position-x: -400px; */
  /* background-position-y: -50px; */
  background-size: 300px 200px;
}

.container-abreasons .card:nth-child(3) .circle,
.container-abreasons .card:nth-child(3) .content a {
  /* background: #bb02ff; */
  background-image: url("https://media.istockphoto.com/vectors/isometric-stack-of-documents-with-an-official-stamp-and-pencils-in-a-vector-id956087486?k=20&m=956087486&s=612x612&w=0&h=-8deOhFH5jf-bwJVWLZbNdflQyr0Ryj_BTvZ-EeFos8=");
  background-repeat: no-repeat;
  background-size: 300px 190px;
}



.about-extra h1{
  font-size: 20rem;
}



/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,300&display=swap');
</style> */

/*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    font-family:'Roboto Condensed', sans-serif ;
}*/
 












/* ===========================================================================   THE CONTACT ===================================================================================== */








.bodyy{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: 'Poppins', sans-serif;
}

.Contactt{
  position: relative;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color:black;
  /*background-image:
  radial-gradient(coral, rgba(126, 126, 126, 0.2) 2px, transparent 40px),
  radial-gradient(cyan, rgba(255,255,255,.15) 1px, transparent 30px),
  radial-gradient(cyan, rgba(255,255,255,.1) 2px, transparent 40px),
  radial-gradient(red, rgba(255,255,255,.1) 2px, transparent 30px);
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;*/
  background-image: url(images/about-img-6.png);
  background-size: cover; 
  background-position:center;
  



}

.Contactt .contentt{

  max-width: 800px;
  text-align: center;


}

.Contactt .contentt h2{
  font-size: 90px;
  font-weight: 500;
  color:rgb(230, 83, 16);
}

.Contactt .contentt p{
  font-weight: 300;
  color: #000;
}

.Containerr
{
  width:100%;
  max-width: 1600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 80px;
  margin-top:-30px ;
  padding: 80px;
  border-radius: 25px;
}

.Containerr .ContactInfoo
{
  width: 50%;
  display: flex;
  flex-direction: column;
  
}
.Containerr .ContactInfoo h2
{
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: 40px;
  color: rgb(230, 83, 16);
  align-items: center;
  
}

.Containerr .ContactInfoo .boxx
{
  position: relative;
  padding: 20px 0;
  display: flex;
  padding-left: 10px;
}

.Containerr .ContactInfoo .boxx1
{
  position: relative;
  padding: 20px 0;
  display: flex;
  padding-left: 10px;
}

.Containerr .ContactInfoo .boxx2
{
  position: relative;
  padding: 20px 0;
  display: flex;
  padding-left: 10px;
}


.Containerr .ContactInfoo .boxx .iconn
{
  min-width: 60px;
  height: 60px;
  background:white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
  color:black;
}

.Containerr .ContactInfoo .boxx1 .iconn
{
  min-width: 60px;
  height: 60px;
  background:white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
  color:black;
}

.Containerr .ContactInfoo .boxx2 .iconn
{
  min-width: 60px;
  height: 60px;
  background:white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
  color:black;
}



.Containerr .ContactInfoo .boxx .texttt
{
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
}


.Containerr .ContactInfoo .boxx1 .texttt
{
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
}


.Containerr .ContactInfoo .boxx2 .texttt
{
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
}

.Containerr .ContactInfoo .boxx .texttt h3
{
  font-weight: 500;
  color: rgb(230, 83, 16);
  margin-bottom: -15px;
  
}

.Containerr .ContactInfoo .boxx1 .texttt h3
{
  font-weight: 500;
  color: rgb(230, 83, 16);
  margin-bottom: -15px;
  
}

.Containerr .ContactInfoo .boxx2 .texttt h3
{
  font-weight: 500;
  color: rgb(230, 83, 16);
  margin-bottom: -15px;
  
}
.boxx
{
  background-color: rgb(0, 0, 0);
  box-shadow: 5px 5px rgb(39, 33, 33);
  background-size: 15px;
  width: 350px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.boxx1
{
  background-color: rgb(0, 0, 0);
  box-shadow: 5px 5px rgb(39, 33, 33);
  background-size: 15px;
  width: 350px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.boxx2
{
  background-color: rgb(0, 0, 0);
  box-shadow: 5px 5px rgb(39, 33, 33);
  background-size: 15px;
  width: 350px;
  border-radius: 15px;
}



.contactForm
{
  width: 50%;
  padding: 40px;
  background:white;
}

.contactForm h2
{
  font-size: 30px;
  color: #333;
  font-weight: 500;
}

.contactForm .InputBox
{
  position: relative;
  width: 100%;
  margin-top: 10px;
  

}
.InputBox input[type="text"]
{
  font-family: 'Poppins', sans-serif;

}

.contactForm .InputBox input,
.contactForm .InputBox textarea
{
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid black;
  outline: none;
  resize: none;
}

.contactForm .InputBox span
{
  position: absolute;
  left: 0;
  padding: 0px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contactForm .InputBox input:focus ~ span,
.contactForm .InputBox input:valid ~ span,
.contactForm .InputBox textarea:focus ~ span,
.contactForm .InputBox textarea:valid ~ span


{
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
  


}

.contactForm .InputBox input[type="submit"]
{
  width: 100px;
  background: #00bcd4;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
}

.contactForm .InputBox input[type="submit"]:hover
{
  transition: 0.2s;
  background: orange;

}
textarea {
  font-family: 'Poppins', sans-serif;
  height: 100px;
}

.iconn2
{
  max-width: 30px;
  height: 30px;
  background:white;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px;
  color:white;
  transform: translateY(100%);
  text-decoration: none;
  
  
}
.Containerr .ContactInfoo .boxx .iconn2 {
  background: #3b5998;
}

.Containerr .ContactInfoo .boxx1 .iconn2 {
  background: #3b5998;
}

.Containerr .ContactInfoo .boxx2 .iconn2 {
  background: #3b5998;
}




.Containerr .ContactInfoo .boxx2 .iconn2 :hover {
  
  color: black;
  transition: 0.2s;
  cursor: pointer;
}


.iconn3
{
  max-width: 30px;
  height: 30px;
  background:white;
  display: flex;
  position: relative;
  transform: translateX(130%);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px;
  color:white;
  text-decoration: none;
}


.Containerr .ContactInfoo .boxx2 .iconn3 {
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 



}

.Containerr .ContactInfoo .boxx2 .iconn3 :hover { 
  color: #000;
  transition: 0.5s;
  cursor: pointer;


}

.iconn4
{
  max-width: 30px;
  height: 30px;
  background:white;
  display: flex;
  position: relative;
  transform: translateX(250%);
  bottom: 30px;
  margin-bottom: -30px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px;
  color:white;
  text-decoration: none;
  
}

.Containerr .ContactInfoo .boxx2 .iconn4 {
  background: #0e76a8; 
}
.Containerr .ContactInfoo .boxx2 .iconn4 :hover {
  color: black;
  transition: 0.2s;
  cursor: pointer; 
}






@media all and (max-width: 990px)
{
  .Contactt
  {
    padding: 50px;
    
  }
  .Containerr
  {
    flex-direction: column;
    margin-top: -30px;
    

  }

  .Containerr .ContactInfoo
  {

    margin-bottom: 50px;
    

  }

  .Containerr .ContactInfoo h2
{
 position: relative;
 width: 100%;

 justify-content: center;
 align-items: center;
  
}

  .Containerr .ContactInfoo,
  .contactForm
  {
    width: 100%;

  }
  .boxx
  {
    width: 100%;
    align-items: center;
  }

  .boxx1
  {
    width: 100%;
    align-items: center;
  }

  .boxx2
  {
    width: 100%;
    align-items: center;
  }
  .Containerr .ContactInfoo .boxx .texttt
{
  display: flex;
  margin-left: 20px;
  font-size: 14px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
  justify-content: center;
}
.Containerr .ContactInfoo .boxx1 .texttt
{
  display: flex;
  margin-left: 20px;
  font-size: 14px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
  justify-content: center;
}
.Containerr .ContactInfoo .boxx2 .texttt
{
  display: flex;
  margin-left: 20px;
  font-size: 14px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
  justify-content: center;
}




}


/*********************** 404 page ************************/

#gen404-container {
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

#gen404-container #header{
  font-size: 52px;
}
